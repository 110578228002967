body {
    background: #f5f5f5;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.button, .button-bg {
    border: none;
    cursor: pointer;
    width: 200px;
    height: 100px;
    font-size: 30px;
    text-align: center;
    line-height: 100px;
    color: rgba(255,255,255,0.9);
    border-radius: 50px;
    background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
    background-size: 600%;
    -webkit-animation: anime 16s linear infinite;
            animation: anime 16s linear infinite;
}

.button .button-bg {
    position: absolute;
    margin-top: -70px;
    z-index: -1;
    filter: blur(30px);
    opacity: 0.8;
}

@keyframes anime {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
