body {
  background: #f5f5f5;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.button, .button-bg {
  cursor: pointer;
  text-align: center;
  color: #ffffffe6;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5) 0 0 / 600%;
  border: none;
  border-radius: 50px;
  width: 200px;
  height: 100px;
  font-size: 30px;
  line-height: 100px;
  animation: 16s linear infinite anime;
}

.button .button-bg {
  z-index: -1;
  filter: blur(30px);
  opacity: .8;
  margin-top: -70px;
  position: absolute;
}

@keyframes anime {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}
/*# sourceMappingURL=index.c0bdf1d1.css.map */
